@charset "UTF-8";
/*
ユーティリティ系おまとめファイル
*/
/*
██████   █████  ███████ ███████
██   ██ ██   ██ ██      ██
██████  ███████ ███████ █████
██   ██ ██   ██      ██ ██
██████  ██   ██ ███████ ███████
*/
/*
██████  ██████  ███████  █████  ██   ██ ██████   ██████  ██ ███    ██ ████████
██   ██ ██   ██ ██      ██   ██ ██  ██  ██   ██ ██    ██ ██ ████   ██    ██
██████  ██████  █████   ███████ █████   ██████  ██    ██ ██ ██ ██  ██    ██
██   ██ ██   ██ ██      ██   ██ ██  ██  ██      ██    ██ ██ ██  ██ ██    ██
██████  ██   ██ ███████ ██   ██ ██   ██ ██       ██████  ██ ██   ████    ██
*/
/*
 ██████  ██████  ██       ██████  ██████
██      ██    ██ ██      ██    ██ ██   ██
██      ██    ██ ██      ██    ██ ██████
██      ██    ██ ██      ██    ██ ██   ██
 ██████  ██████  ███████  ██████  ██   ██
*/
/*
████████ ███████ ██   ██ ████████
   ██    ██       ██ ██     ██
   ██    █████     ███      ██
   ██    ██       ██ ██     ██
   ██    ███████ ██   ██    ██
*/
.c-card, .c-interview .c-interview__card {
  margin-bottom: 30px; }
  .c-card__head {
    border: 2px solid #000000;
    padding: 30px;
    display: flex;
    align-items: start;
    flex-wrap: wrap;
    max-width: 100%;
    background: #FFFFFF; }
    @media screen and (max-width: 1024px) {
      .c-card__head {
        padding: 15px; } }
    .c-card__head .c-label {
      width: 140px;
      justify-content: center;
      margin-bottom: 0; }
      @media screen and (max-width: 1024px) {
        .c-card__head .c-label {
          width: 100px; } }
    .c-card__head__main {
      padding-left: 30px;
      font-size: 28px;
      margin-bottom: 0;
      width: calc(100% - 140px);
      margin-top: 0; }
      @media screen and (max-width: 1024px) {
        .c-card__head__main {
          width: calc(100% - 100px);
          font-size: 20px;
          padding-left: 15px; } }
  .c-card__body__content {
    background: #FFFFFF;
    padding: 30px;
    border: 2px solid #000000;
    border-top: 0;
    margin-bottom: 0; }
    @media screen and (max-width: 1024px) {
      .c-card__body__content {
        padding: 15px; } }
  .c-card__body__link {
    width: 276px;
    margin-left: auto; }
    @media screen and (max-width: 1024px) {
      .c-card__body__link {
        width: 218px; } }
    .c-card__body__link a {
      width: 100%;
      border-top: 0; }

.c-mainVisual--home {
  margin-top: -61px;
  background: url(/inc/image/home/bg_main.png) center center/1900px auto #388CDB;
  height: 850px;
  max-height: calc(100vh + 50px);
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center; }
  @media screen and (max-width: 768px) {
    .c-mainVisual--home {
      background: none;
      height: inherit;
      max-height: inherit; } }
  .c-mainVisual--home__inner {
    height: 642px;
    width: 642px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    position: relative;
    background: radial-gradient(#FFFFFF 60%, rgba(255, 255, 255, 0) 60%); }
    @media screen and (max-width: 768px) {
      .c-mainVisual--home__inner {
        background: none;
        display: block;
        height: inherit;
        width: 100%; } }
    .c-mainVisual--home__inner::before {
      opacity: 0;
      animation: rotate .8s .5s forwards;
      position: absolute;
      content: '';
      display: block;
      width: 730px;
      height: 730px;
      left: -44px;
      top: -44px;
      background: url(/inc/image/home/parts_shinning.png) 45px 105px/152px auto no-repeat, url(/inc/image/home/parts_shinning.png) right 45px bottom 105px/152px auto no-repeat; }
      @media screen and (max-width: 768px) {
        .c-mainVisual--home__inner::before {
          content: none; } }
  .c-mainVisual--home .l-content {
    position: relative;
    overflow: inherit; }
    .c-mainVisual--home .l-content .c-btn--toBottom {
      position: absolute;
      left: 20px;
      bottom: 40px; }

.c-mainVisual__coution {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: right;
  color: #fff; }
  @media screen and (max-width: 768px) {
    .c-mainVisual__coution {
      top: 390px;
      bottom: inherit;
      transform: translateY(-100%);
      z-index: 1; } }
  .c-mainVisual__coution p {
    padding: 0 20px 15px; }
    @media screen and (max-width: 768px) {
      .c-mainVisual__coution p {
        padding: 0 15px 5px;
        font-size: 11px; } }
  .c-mainVisual__coution::after {
    display: block;
    content: '';
    background: #FFFFFF;
    width: 100%;
    height: 50px; }
    @media screen and (max-width: 768px) {
      .c-mainVisual__coution::after {
        content: none; } }

.c-mainVisual__heading {
  font-size: 32px;
  text-align: center;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  letter-spacing: 0.1em; }
  @media screen and (max-width: 768px) {
    .c-mainVisual__heading {
      font-size: 20px;
      margin-bottom: 15px;
      background: url(/inc/image/home/bg_main.png) center center/870px auto #388CDB;
      height: 390px;
      position: relative;
      z-index: 1; }
      .c-mainVisual__heading::before {
        content: '';
        z-index: -1;
        width: 245px;
        height: 245px;
        display: block;
        top: calc(50% - 122.5px);
        left: calc(50% - 122.5px);
        position: absolute;
        background: #fff;
        border-radius: 50%; }
      .c-mainVisual__heading::after {
        opacity: 0;
        animation: rotate .8s .5s forwards;
        position: absolute;
        content: '';
        display: block;
        width: 280px;
        height: 280px;
        top: calc(50% - 140px);
        left: calc(50% - 140px);
        background: url(/inc/image/home/parts_shinning.png) 15px 20px/50px auto no-repeat, url(/inc/image/home/parts_shinning.png) right 15px bottom 20px/50px auto no-repeat; } }
  .c-mainVisual__heading__sub {
    width: 140px;
    height: auto;
    margin: -15px auto 25px;
    display: block; }
    @media screen and (max-width: 768px) {
      .c-mainVisual__heading__sub {
        width: 82px;
        margin-bottom: 20px; } }

.c-mainVisual__lead {
  text-align: center;
  margin-bottom: 20px;
  padding: 0 20px;
  line-height: 1.8; }
  @media screen and (max-width: 768px) {
    .c-mainVisual__lead {
      padding: 10px 15px 0;
      font-size: 14px;
      text-align: left; }
      .c-mainVisual__lead .pc {
        display: none; } }

.c-mainVisual__btn {
  height: 50px;
  width: 207px; }

@keyframes rotate {
  0% {
    opacity: 0;
    transform: rotate(0); }
  20% {
    opacity: .9;
    transform: rotate(0); }
  100% {
    opacity: 1;
    transform: rotate(360deg); } }

.c-panel, .c-panel--right, .c-panel--lg {
  display: flex;
  justify-content: space-between;
  margin-bottom: 70px;
  align-items: flex-start; }
  .c-panel:last-child, .c-panel--right:last-child, .c-panel--lg:last-child {
    margin-bottom: 0; }
  @media screen and (max-width: 768px) {
    .c-panel, .c-panel--right, .c-panel--lg {
      display: block; } }
  .c-panel > img, .c-panel--right > img, .c-panel--lg > img {
    width: calc(50% - 15px); }
    @media screen and (max-width: 768px) {
      .c-panel > img, .c-panel--right > img, .c-panel--lg > img {
        width: 100%;
        display: block;
        margin-bottom: 30px; } }
  .c-panel__img {
    width: calc(50% - 15px); }
    .c-panel__img img {
      width: 100%; }
    @media screen and (max-width: 768px) {
      .c-panel__img {
        width: 100%;
        display: block;
        margin-top: 30px; } }
  .c-panel__main {
    width: calc(50% - 15px); }
    @media screen and (max-width: 768px) {
      .c-panel__main {
        width: 100%;
        margin-bottom: 20px; } }
  .c-panel .c-btn--more, .c-panel--right .c-btn--more, .c-panel--lg .c-btn--more {
    width: 278px;
    margin-right: 0; }
    @media screen and (max-width: 768px) {
      .c-panel .c-btn--more, .c-panel--right .c-btn--more, .c-panel--lg .c-btn--more {
        width: 237px; } }
  .c-panel__heading {
    font-size: 32px;
    border-bottom: 2px solid #000000;
    padding-bottom: 20px;
    margin-bottom: 40px; }
    @media screen and (max-width: 1024px) {
      .c-panel__heading {
        font-size: 24px; } }
    @media screen and (max-width: 768px) {
      .c-panel__heading {
        margin-top: 15px; } }
    .c-panel__heading__main {
      display: flex;
      align-items: center;
      flex-grow: 1;
      height: 100%;
      margin-bottom: 0; }
    .c-panel__heading--count {
      display: flex;
      align-items: center;
      margin-bottom: 20px; }
      .c-panel__heading--count .c-panel__heading__main {
        font-size: 35px;
        line-height: 1.4;
        letter-spacing: 0.05em; }
        @media screen and (max-width: 768px) {
          .c-panel__heading--count .c-panel__heading__main {
            font-size: 24px; } }
      .c-panel__heading--count .c-label--point {
        margin-right: 20px; }
  .c-panel--right {
    flex-direction: row-reverse; }
    .c-panel--right .c-btn--more {
      margin-left: 0;
      margin-right: auto; }
      @media screen and (max-width: 768px) {
        .c-panel--right .c-btn--more {
          margin-left: auto;
          margin-right: 0; } }
  @media screen and (max-width: 768px) {
    .c-panel--lg {
      display: flex; } }
  .c-panel--lg .c-panel__main {
    width: 75%;
    padding-right: 30px; }
  .c-panel--lg img {
    width: 25%; }

.c-hover,
a.c-hover:not([class^="c-btn"]) {
  opacity: 1;
  transition: 0.3s ease; }
  .c-hover:hover,
  a.c-hover:not([class^="c-btn"]):hover {
    opacity: 0.75; }

.c-products {
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
  margin-bottom: 30px; }
  @media screen and (max-width: 768px) {
    .c-products {
      display: block; } }
  .c-products > * {
    width: calc(33.333% - 30px);
    margin-right: 45px;
    margin-bottom: 20px; }
    @media screen and (max-width: 1024px) {
      .c-products > * {
        width: calc(50% - 10px);
        margin-right: 20px;
        margin-bottom: 30px; } }
    @media screen and (max-width: 768px) {
      .c-products > * {
        width: 100%; } }
    .c-products > *:nth-child(3n) {
      margin-right: 0; }
      @media screen and (max-width: 1280px) {
        .c-products > *:nth-child(3n) {
          margin-right: 20px; } }
      @media screen and (max-width: 1280px) {
        .c-products > *:nth-child(3n) {
          margin-right: 0; } }
    @media screen and (max-width: 1024px) {
      .c-products > *:nth-child(2n) {
        margin-right: 0; } }

.c-productsItem {
  text-decoration: none; }
  .c-productsItem img {
    display: block;
    margin-bottom: 20px; }
  .c-productsItem__heading .c-label {
    margin-bottom: 5px; }
    .c-productsItem__heading .c-label + * {
      font-size: 23px;
      margin-bottom: 20px; }
      @media screen and (max-width: 768px) {
        .c-productsItem__heading .c-label + * {
          font-size: 19px; } }
  .c-productsItem__headingSm {
    color: #000000; }
  .c-productsItem .c-btn--more--sm {
    color: #000000;
    text-decoration: none;
    color: #000000;
    letter-spacing: 0.1em;
    font-weight: bold;
    display: inline-block;
    padding-bottom: 3px;
    position: relative; }
    .c-productsItem .c-btn--more--sm::before {
      position: absolute;
      bottom: 0;
      left: 0;
      content: '';
      width: 0;
      height: 2px;
      background: #000000;
      display: block;
      transition: 0.3s ease; }
    .c-productsItem .c-btn--more--sm:hover {
      color: #000000; }
      .c-productsItem .c-btn--more--sm:hover::before {
        width: 100%; }
  .c-productsItem::after {
    content: none !important; }
  .c-productsItem a[target="_blank"] {
    color: #000000;
    font-weight: bold;
    letter-spacing: 0.04em;
    text-decoration: none;
    position: relative; }
    .c-productsItem a[target="_blank"]::before {
      content: '';
      display: block;
      position: absolute;
      bottom: -4px;
      left: 0;
      background: #000000;
      height: 2px;
      width: 0;
      transition: 0.3s ease; }
    .c-productsItem a[target="_blank"]:hover::before {
      width: 100%; }

.u-bg--gray {
  background: radial-gradient(#f9f9f9 40%, #e8e8e8 120%); }

.u-bg--whiteGray {
  position: relative;
  z-index: 1; }
  .u-bg--whiteGray::after {
    content: '';
    z-index: -1;
    display: block;
    position: absolute;
    left: 0;
    top: 50px;
    width: 100%;
    height: calc(100% - 50px);
    background: radial-gradient(#f9f9f9 40%, #e8e8e8 120%); }

.u-txt--center {
  text-align: center; }

.u-txt--right {
  text-align: right; }

.u-txt--bold {
  font-weight: bold; }

.u-txt--Lg {
  font-size: 24px;
  letter-spacing: .05em; }
  @media screen and (max-width: 1024px) {
    .u-txt--Lg {
      font-size: 15px; } }

.c-topNewsList {
  margin: 50px 0; }
  .c-topNewsList__item a {
    color: #000000;
    margin-bottom: 20px;
    flex-wrap: wrap;
    display: flex;
    max-width: 100%;
    text-decoration: none; }
  .c-topNewsList__item:last-child {
    margin-bottom: 0; }
  .c-topNewsList__item time {
    font-weight: bold;
    width: 150px;
    display: inline-block;
    color: #000000; }
    .c-topNewsList__item time span {
      font-size: 21px;
      font-family: "Oswald", sans-serif; }
      @media screen and (max-width: 1024px) {
        .c-topNewsList__item time span {
          font-size: 19px; } }
  .c-topNewsList__item .c-label--sm {
    width: 140px;
    justify-content: center; }
  .c-topNewsList__item__txt {
    margin-top: 0;
    display: block;
    width: calc(100% - 300px);
    margin-left: 10px;
    color: #000000;
    text-decoration: none; }
    @media screen and (max-width: 1024px) {
      .c-topNewsList__item__txt {
        width: 100%;
        margin-top: 10px;
        margin-left: 0; } }

.l-mt--lg {
  margin-top: 100px; }

.c-interview {
  padding-top: 50px;
  padding-bottom: 50px;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: flex-start;
  max-width: 100%; }
  @media screen and (max-width: 768px) {
    .c-interview {
      display: block; } }
  .c-interview__img {
    width: calc(468px + ((100% - 1170px) / 2) + 75px);
    margin-left: -60px;
    margin-right: -15px;
    display: block;
    max-height: 500px;
    overflow: hidden; }
    .c-interview__img img {
      width: 100%;
      position: relative; }
      @media screen and (min-width: 1280px) {
        .c-interview__img img {
          max-width: 735px; } }
    @media screen and (max-width: 1280px) {
      .c-interview__img {
        width: calc(40% + 75px); } }
    @media screen and (max-width: 768px) {
      .c-interview__img {
        margin-left: auto;
        width: 85%;
        margin-right: 0; } }
  .c-interview .c-interview__card {
    margin-top: 50px;
    z-index: 10;
    position: relative;
    width: 702px;
    margin-left: calc((100% - 1170px) / 2 + 15px); }
    @media screen and (max-width: 1280px) {
      .c-interview .c-interview__card {
        width: calc(60% - 15px);
        margin-left: 15px; } }
    @media screen and (max-width: 768px) {
      .c-interview .c-interview__card {
        margin-top: -50px;
        width: inherit;
        margin-right: 15px; } }
    .c-interview .c-interview__card .c-label--sm {
      width: 171px; }
      .c-interview .c-interview__card .c-label--sm + * {
        margin-top: 0; }
    .c-interview .c-interview__card .c-card__head__main {
      font-size: 23px;
      width: calc(100% - 190px); }
      @media screen and (max-width: 1280px) {
        .c-interview .c-interview__card .c-card__head__main {
          font-size: 20px; } }
      @media screen and (max-width: 768px) {
        .c-interview .c-interview__card .c-card__head__main {
          width: 100%;
          padding-left: 0;
          margin-top: 10px; } }
    .c-interview .c-interview__card .c-card__body__content {
      font-size: 26px;
      font-weight: bold; }
      @media screen and (max-width: 1280px) {
        .c-interview .c-interview__card .c-card__body__content {
          font-size: 22px;
          line-height: 1.5; } }

.u-pb-lg {
  padding-bottom: 50px; }

.c-topBannerArea {
  margin-bottom: 120px; }
  @media screen and (max-width: 768px) {
    .c-topBannerArea {
      margin-bottom: 70px; } }

.c-productsItem {
  text-decoration: none; }
  .c-productsItem__headingSm,
  .c-productsItem img {
    opacity: 1;
    transition: 0.3s ease; }
  .c-productsItem:hover img {
    opacity: 0.75; }
  .c-productsItem:hover .c-productsItem__headingSm {
    opacity: 0.75; }
  .c-productsItem:hover .c-btn--more--sm::after {
    transform: translateX(0); }
  .c-productsItem:hover .c-btn--more--sm::before {
    width: 100%; }
